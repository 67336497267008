import { BaseNoPaddingBtn, Loader, PrimaryHeader, PrimaryLabel, SingleLineInput, SolidBtn } from "NstyleComponents";
import React, { useEffect, useState } from 'react';
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ReactSVG } from "react-svg";
import * as actions from '../../../actions/auth/signInAction';
import settings from '../../../configs/appSettings';
import addTracking from "../../../helpers/addTracking";
import { DocTitle } from '../../common/DocTitle';
import { imageCdnUrls } from "../../common/ImageCdnUrls";
import MarketingContent from "../marketing/MarketingContent";
import './_style.scss';

interface SignInPageProps {
    referrer: any;
}

const SignInPage = ({
    referrer
}: SignInPageProps) => {

    const history = useHistory();
    const dispatch = useDispatch();

    const [username, setUsername] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [usernameIsValid, setUsernameIsValid] = useState<boolean>(true);
    const [passwordIsValid, setPasswordIsValid] = useState<boolean>(true);
    const [authError, setAuthError] = useState<string>("");
    const [working, setWorking] = useState<boolean>(false);
    const [showPasswordField, setShowPasswordField] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState(false);
    const [inputType, setInputType] = useState<string>("password");

    const status = useSelector((state: any) => state.auth.status);
    const pwResetStatus = useSelector((state: any) => state.resetPassword.status);
    const statusText = useSelector((state: any) => state.auth.statusText);
    const nonce = useSelector((state: any) => state.auth.nonce);
    const ssoRedirectUrl = useSelector((state: any) => state.auth.ssoRedirectUrl);
    const customerId = useSelector((state: any) => state.auth.customerId);
    const userId = useSelector((state: any) => state.auth.userId);

    const resetPageForAuthenticationError = (errorMessage: string) => {
        setWorking(false);
        setUsername("");
        setPassword("");
        setAuthError(errorMessage);
        dispatch(actions.clearStatus());
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleAuthenticationStatusChanged = () => {
        switch (status) {
            case "InvalidUsernamePassword": {
                resetPageForAuthenticationError("Your username or password doesn't match what we have on file. Try signing in again.");
                break;
            }
            case "InvalidIpAddress": {
                addTracking(userId);
                resetPageForAuthenticationError("Your IP Address is not allowed.");
                break;
            }
            case "LogInToPlatformError": {
                resetPageForAuthenticationError(statusText);
                break;
            }
            case "CustomerSelectionNeeded": {
                addTracking(userId);
                history.push("/auth/customer-selection");
                break;
            }
            case "2FASelection": {
                addTracking(userId);
                history.push('/auth/requestIdentificationMethods');
                break;
            }
            case "PasswordExpired": {
                addTracking(userId);
                history.push(`/auth/ResetPassword/${nonce}?e=1`);
                break;
            }
            case "AccountLocked": {
                addTracking(userId);
                history.push(`/auth/AccountLocked/${username}`);
                break;
            }
            case "UserDoesNotHaveSso": {
                setShowPasswordField(true);
                setWorking(false);
                break;
            }
            case "UserHasIdpInit": {
                window.location.href = ssoRedirectUrl;
                break;
            }
            case "UserHasSpInit": {
                window.location.href = `${settings.authApiUrl}/sso/saml2/InitiateSingleSignOn?customerId=${encodeURIComponent(customerId)}&referrer=${encodeURIComponent(referrer.platform)}&returnUrl=${encodeURIComponent(referrer.platformRequestedRedirectUrl)}&targetCustomerId=${encodeURIComponent(referrer.customerId)}`;
                break;
            }
            case "LogInFail": {
                resetPageForAuthenticationError("An error occurred when attempting to login. If you continue to receive this error, please contact customer support.");
                break;
            }
            default:
                break;
        }
    };

    const handlePasswordChanged = (password: string) => {
        setPassword(password);
        setAuthError("");
        setPasswordIsValid(true);
    };

    const handleUsernameChanged = (username: string) => {
        setUsername(username);
        setAuthError("");
        setUsernameIsValid(true);
    };

    const validate = () => {
        const isPasswordValid = password !== undefined && password.trim().length > 0;
        const isUsernameValid = username !== undefined && username.trim().length > 0;

        setUsernameIsValid(isUsernameValid);
        setPasswordIsValid(isPasswordValid);

        return isPasswordValid && isUsernameValid;
    };

    const handleSignInClicked = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.KeyboardEvent) => {
        handleSignIn(event);
    };

    const handleKeyPressed = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            handleSignInClicked(event);
        }
    };

    const handleSignIn = (event: React.MouseEvent | React.KeyboardEvent) => {
        if (showPasswordField) {
            event.preventDefault();
            if (validate()) {
                setWorking(true);
                dispatch(actions.logIn(username, password, referrer.platform, referrer.platformRequestedRedirectUrl, referrer.isSSO, referrer.customerId));
            }
            else {
                return;
            }
        } else {
            setWorking(true);
            dispatch(actions.checkSso(username, referrer.customerId));
        }
    };

    useEffect(() => {
        dispatch(actions.checkSsoInit(document.referrer, window.location.hostname));
        dispatch(actions.logout());
    }, []);

    useEffect(() => {
        handleAuthenticationStatusChanged();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status]);

    useEffect(() => {
        setInputType(showPassword ? "text" : "password");
    }, [showPassword]);


    return (
        <div>
            <DocTitle syncTo="Sign in to Ncontracts" />
            <div className="sign-in-page columns">
                <div className={"sign-in-page__wrapper"} style={{ background: `url(${imageCdnUrls.singleBlobBackground}) no-repeat center / contain` }}>
                    <div className="sign-in-page__form">
                        <div className="column-grouping">
                            <Loader showLoader={working} type={"--branded"}>
                                <div className="sign-in__header">
                                    <ReactSVG
                                        src={imageCdnUrls.nLogoWhite}
                                        className={"sign-in__header-logo"}
                                    />
                                    <PrimaryHeader className={"sign-in__header"}>Sign in</PrimaryHeader>
                                    {pwResetStatus === 'PasswordResetSuccess' && (
                                        <div className={"sign-in__header-resetPW-success"}>
                                            <ReactSVG
                                                src={imageCdnUrls.successNotification}
                                                className={"sign-in__header-resetPW-success-icon"}
                                            />
                                            Password updated! Please try signing in again with your new password.
                                        </div>
                                    )}
                                </div>
                                <div className="sign-in-page__form-fields">
                                    <div className={"sign-in-page__form-field"}>
                                        <PrimaryLabel className={"sign-in__form-field-label"}>Email</PrimaryLabel>
                                        <SingleLineInput
                                            onChange={(email) => handleUsernameChanged(email)}
                                            value={username}
                                            placeholder={"example@gmail.com"}
                                            isValid={usernameIsValid}
                                            onKeyPress={e => handleKeyPressed(e)}
                                            {...{ autoFocus: true, type: "email", id: "userName" }}
                                            className={"sign-in-page__form-field-input"}
                                        />
                                        {!usernameIsValid &&
                                            <div className={"auth-error"}>
                                                Incorrect email address
                                            </div>
                                        }
                                    </div>
                                    <div className={"sign-in-page__form-field-wrapper"}>
                                        {showPasswordField && (
                                            <div className={"sign-in-page__form-field"}>
                                                <PrimaryLabel className={"sign-in__form-field-label"}>Password</PrimaryLabel>
                                                <SingleLineInput
                                                    onChange={(pwd) => handlePasswordChanged(pwd)}
                                                    value={password}
                                                    placeholder={"Password"}
                                                    onKeyPress={e => handleKeyPressed(e)}
                                                    isValid={passwordIsValid}
                                                    {...{ type: inputType,id: "password"}}
                                                    className={"sign-in-page__form-field-input"}
                                                />
                                                {showPassword ? (
                                                    <FaEyeSlash onClick={togglePasswordVisibility} className="eye-icon" />
                                                ) : (
                                                    <FaEye onClick={togglePasswordVisibility} className="eye-icon" />
                                                )}
                                                {!passwordIsValid &&
                                                    <div className={"auth-error"}>
                                                        Incorrect password
                                                    </div>
                                                }
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className={authError.length > 0 ? 'auth-error' : 'hidden-div'}>
                                    {authError}
                                </div>
                                <div className={"sign-in__forgot-password"}>
                                    <BaseNoPaddingBtn
                                        className={"sign-in__forgot-password-btn"}
                                        onClick={() => history.push("/auth/sendPasswordResetLink")}>
                                        Forgot Password?
                                    </BaseNoPaddingBtn>
                                </div>
                                <div>
                                    <SolidBtn
                                        tabIndex={0}
                                        onClick={(e) => handleSignInClicked(e)}
                                        className={"sign-in__button"}
                                    >
                                        {showPasswordField ? "Sign In" : "Next"}
                                    </SolidBtn>
                                </div>
                                <div className={"sign-in__contact-support"}>
                                    <div className={"sign-in__contact-support-left"}>
                                        Having trouble?
                                    </div>
                                    <BaseNoPaddingBtn
                                        className={"sign-in__contact-support-btn"}
                                        onClick={() => { window.location.href = "https://help.ncontracts.com/s/contactsupport"; }}>
                                        Contact Support
                                    </BaseNoPaddingBtn>
                                </div>
                            </Loader>
                        </div>
                    </div>
                </div>
                <MarketingContent />
            </div>
        </div>
    );
};

export default SignInPage;
