import http from "./httpService";
import { CurrentUser } from "../currentUser";
import settings from '../../configs/appSettings';

class authService {
    static logIn(username, password, clientId, clientRequestedRedirectUrl, isSSO, customerId) {
        const closure = this;
        const closureUrl = clientRequestedRedirectUrl;

        return new Promise((resolve, reject) => {
            http.login(username, password, clientId, customerId).then(
                (result) => {
                    if (isSSO) {
                        closure.transferToSpInitiatedSso(result)
                            .then(result => resolve(result),
                                err => reject(err));
                    } else {
                        closure.callbackToPlatformClient(clientId,result,closureUrl)
                            .then(result => resolve(result),
                                err => resolve(err));
                    }
                },
                (err) => reject(err)
            );
        });
    }

    static selectCustomer(nonce, hash, clientId, clientRequestedRedirectUrl, isSSO) {
        const closure = this;
        const closureUrl = clientRequestedRedirectUrl;
        return new Promise((resolve, reject) => {
            http.selectCustomer(nonce, hash, clientId).then(
                (result) => {
                    if (isSSO) {
                        closure.transferToSpInitiatedSso(result)
                            .then(result => resolve(result),
                                err => reject(err));
                    } else {
                        closure.callbackToPlatformClient(clientId,result,closureUrl)
                            .then(result => resolve(result),
                                err => resolve(err));
                    }
                },
                (err) => reject(err)
            );
        });
    }

    static confirmOtp(nonce, code, clientId, clientRequestedRedirectUrl, isSSO, rememberDevice) {
        const closure = this;
        const closureUrl = clientRequestedRedirectUrl;

        return new Promise((resolve, reject) => {
            http.confirmOtp(nonce, code, clientId, rememberDevice).then(
                (result) => {
                    if (isSSO) {
                        closure.transferToSpInitiatedSso(result)
                            .then(result => resolve(result),
                                err => reject(err));
                    } else {
                        closure.callbackToPlatformClient(clientId,result,closureUrl)
                            .then(result => resolve(result),
                                err => resolve(err));
                    }
                },
                (err) => reject(err)
            );
        });
    }

    static callbackToPlatformClient(clientId, oauthResult, clientRequestedRedirectUrl) {
        return new Promise((resolve) => {
            let authenticatedUserResponse = {
                userId: oauthResult.userId,
                redirectTo: ''
            };

            if (oauthResult.destinationUrl != null && oauthResult.destinationUrl.trim().length > 0) {
                resolve({...authenticatedUserResponse, redirectTo: oauthResult.destinationUrl});
                return;
            }

            http.post('',
                {
                    authToken: oauthResult.access_token,
                    refreshToken: oauthResult.refresh_token,
                    tokenType: oauthResult.token_type,
                    authTokenLifetimeInSeconds: oauthResult.expires_in,
                    userId: oauthResult.userId,
                    requestedReturnUrl: clientRequestedRedirectUrl || "",
                    refreshTokenExpirationMinutes: oauthResult.refreshTokenExpirationMinutes
                },
                oauthResult.platformClientCallbackUrl,
                true)
                .then(result => {
                    if (result.redirectUrl) {
                        authenticatedUserResponse.redirectTo = result.redirectUrl;
                    }
                    else if (result.d) {
                        //To support WebMethod()
                        if (typeof result.d === "string") {
                            authenticatedUserResponse.redirectTo = JSON.parse(result.d).redirectUrl;
                        }
                        else {
                            authenticatedUserResponse.redirectTo = result.d.redirectUrl;
                        }
                    }
                    return resolve(authenticatedUserResponse);
                },
                    err => {
                        if (err.status === 401) {
                            window.location.href = '/errorPage';
                        }
                        else {
                            resolve(authenticatedUserResponse);
                        }
                    });
        });
    }

    static transferToSpInitiatedSso(oauthResult) {
        return new Promise((resolve, reject) => {
            http.post("",
                {
                    authToken: oauthResult.access_token,
                    refreshToken: oauthResult.refresh_token,
                    tokenType: oauthResult.token_type,
                    authTokenLifetimeInSeconds: oauthResult.expires_in,
                    userId: oauthResult.userId,
                    requestedReturnUrl: "",
                    refreshTokenExpirationMinutes:
                        oauthResult.refreshTokenExpirationMinutes,
                },
                `${settings.authApiUrl}/sso/transfer/sp/help`, // instead of "help", we want to pass another SsoProduct enum. we only support help right now
                true
            ).then(
                (result) => {
                    window.location = result.redirectUrl;
                },
                (err) => {
                    if (err.status === 401) {
                        reject(err);
                    } else {
                        resolve({
                            userId: oauthResult.userId,
                            redirectTo: "",
                        });
                    }
                }
            );
        });
    }

    static requestPassword(email, referrerPlatform) {
        return new Promise((resolve, reject) => {
            http.callApiAnonymously('POST', 'ResetPassword', { email: email, referrer: referrerPlatform })
                .then(() => {
                    resolve();
                }, err => {
                    reject(err);
                });
        });
    }

    static resetPassword(nonce, newPassword) {
        return new Promise((resolve, reject) => {
            http.callApiAnonymously('PUT', 'ResetPassword', { nonce: nonce, newPassword: newPassword })
                .then(() => {
                    resolve();
                }, err => {
                    reject(err);
                });
        });
    }

    static sendOtp(nonce, modality) {
        return new Promise((resolve, reject) => {
            http.callApiAnonymously('POST', 'OneTimePassword/Send', { nonce: nonce, modality: modality })
                .then(() => {
                    resolve();
                }, err => {
                    reject(err);
                });
        });
    }

    static checkSsologIn(emailAddress, customerId) {
        return new Promise((resolve, reject) => {
            http.get("sso/identify", { email: emailAddress, ti: customerId })
                .then(result => resolve(result),
                    err => reject(err));
        });
    }

    static ensureTokenValidity(clientId) {
        return new Promise((resolve) => {
            http.refreshToken(clientId)
            .then(result => resolve(result),
                  () => resolve(false));
        });
    }

    static loggedIn() {
        return (CurrentUser.refreshToken != null);
    }

    static logout() {
        return new Promise((resolve) => {
            http.post("logout", undefined, settings.authApiUrl + "/")
                .then(() => resolve())
                .catch(() => resolve());  //even if this fails we want to log the user out
        });
    }
}

export default authService;
